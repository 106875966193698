import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Title for page Service1",
  "description": "Description for page Servizio1",
  "h1": "Lorem ipsum dolor sit amet, consectetur adipiscing",
  "h2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContainerFixed = makeShortcode("ContainerFixed");
const SectionFullWidth = makeShortcode("SectionFullWidth");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ContainerFixed mdxType="ContainerFixed">
      <SectionFullWidth mdxType="SectionFullWidth">
        <h1>{`Service1`}</h1>
        <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec risus dui, eleifend ac facilisis in, pretium in sapien. Morbi a sem eget urna laoreet placerat nec a orci. Vivamus quis lectus sit amet turpis commodo viverra in eget urna. Aenean at feugiat leo.`}</p>
        <p>{`Quisque at sodales ex. Mauris eget ultricies ipsum. Cras vel mollis nisi. Duis sed orci quis elit tempus eleifend ut non nisi. Ut blandit finibus felis nec pharetra. Duis maximus commodo libero. In hac habitasse platea dictumst. Duis feugiat purus sed magna tempus, suscipit laoreet odio lacinia. Aenean tincidunt sapien at est luctus suscipit. Morbi quis pellentesque nisi. Donec nec est tempor, congue lorem a, egestas est. Phasellus sit amet feugiat justo. Morbi quis venenatis justo.`}</p>
      </SectionFullWidth>
    </ContainerFixed>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      